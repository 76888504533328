<template>
  <ion-page>
    <ion-header class="ion-no-border">
      <ion-toolbar>
        <ion-grid>
          <ion-row class="align-center" id="row">
            <ion-col size="9" class="d-flex">
              <ion-buttons class="mr-2" @click="back">
                <ion-icon size="small" :icon="arrowBackOutline"></ion-icon>
              </ion-buttons>
              <ion-label class="fs-4 fw-bold">{{ $t('change_password') }}</ion-label>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-toolbar>
    </ion-header>
    <ion-content fullscreen>
      <password-form @update-password="updatePassword" :key="key" />
    </ion-content>
    <!-- modal info -->
    <ion-modal
      :is-open="isOpenModalInfoRef"
      css-class="modal-info custom-modal"
      @didDismiss="setOpenModalInfo(false)"
    >
      <modal-content-info :title="modalInfoTitle" :content="modalInfoContent" @close-modal="closeModalInfo">
      </modal-content-info>
    </ion-modal>

    <!-- loading -->
    <ion-loading
      :is-open="isOpenLoadingref"
      @didDismiss="setOpenLoading(false)"
      cssClass="custom-loading"
      message=""
      spinner="crescent"
    >
    </ion-loading>
  </ion-page>
</template>

<script>
import { defineComponent, ref } from 'vue';
import PasswordForm from './PasswordForm.vue';
import ModalContentInfo from '@/modules/b2b/views/shared/ModalContentInfo.vue';
import { arrowBackOutline } from 'ionicons/icons';

import { createNamespacedHelpers } from 'vuex';
import { ACTIONS } from '@/modules/b2b/store/account/actions';

const { mapActions, mapGetters } = createNamespacedHelpers('b2b/account');
import { inject } from 'vue';

export default defineComponent({
  name: 'UpdateProfile',
  components: {
    // User Defined component
    PasswordForm,
    ModalContentInfo
  },
  data() {
    return {
      user: null,
      hasError: false,
      key: 0,

      titleSuccess: this.$t('success'),
      titleFailed: this.$t('failed'),
      modalInfoTitle: '',
      modalInfoContent: '',
      // Old password doesn't match
      contentOldPasswordNotMatch: this.$t('accountPage.old_password_doesnt_match'),
      // Your password has beed saved
      contentSuccessPassword: this.$t('accountPage.your_password_has_been_saved')
    };
  },
  setup() {
    const ionRouter = inject('navManager');

    // modal info
    const isOpenModalInfoRef = ref(false);
    const setOpenModalInfo = (state) => (isOpenModalInfoRef.value = state);

    // loading
    const isOpenLoadingref = ref(false);
    const setOpenLoading = (state) => (isOpenLoadingref.value = state);
    return {
      // modal info
      isOpenModalInfoRef,
      setOpenModalInfo,
      // loading
      isOpenLoadingref,
      setOpenLoading,
      arrowBackOutline,
      ionRouter
    };
  },
  watch: {
    $route() {
      this.key = this.key + 1;
    }
  },
  inject: ['$storage'],
  async mounted() {
    this.user = await this.$storage.getUser();
  },
  computed: {
    ...mapGetters(['checkOldPassword', 'status', 'error'])
  },
  methods: {
    ...mapActions([ACTIONS.CHECK_OLD_PASSWORD, ACTIONS.UPDATE_PASSWORD, ACTIONS.RESET_STATUS_ERROR]),
    async validateOldPassword(password) {
      await this[ACTIONS.CHECK_OLD_PASSWORD]({ password });
      return this.checkOldPassword && this.status;
    },
    async updatePassword(passwordInfo) {
      this.setOpenLoading(true);
      const { currentPassword, newPassword } = passwordInfo;
      const checkOldPassword = await this.validateOldPassword(currentPassword);
      // check old password
      if (!checkOldPassword) {
        this.modalInfoTitle = this.titleFailed;
        this.modalInfoContent = this.contentOldPasswordNotMatch;
        this.hasError = true;
        this.setOpenLoading(false);
        this.setOpenModalInfo(true);
        return;
      }

      // update password
      const params = {
        userId: this.user.id,
        password: newPassword
      };
      await this[ACTIONS.UPDATE_PASSWORD](params);
      this.setOpenLoading(false);
      // show modal info
      if (this.status) {
        this.modalInfoTitle = this.titleSuccess;
        this.modalInfoContent = this.contentSuccessPassword;
      } else {
        this.modalInfoTitle = this.titleFailed;
        this.modalInfoContent = this.error.message;
        this[ACTIONS.RESET_STATUS_ERROR]();
      }
      this.setOpenModalInfo(true);
    },
    closeModalInfo() {
      this.setOpenModalInfo(false);
      if (this.hasError) {
        this.hasError = false;
        return;
      }
      this.ionRouter.navigate({
        routerLink: '/b2b/account'
      });
    },
    back() {
      window.history.back();
    }
  }
});
</script>
