<template>
  <vee-form class="px-3" :validation-schema="productSchema">
    <!-- current password -->
    <ion-item :class="['fs-2 custom-item', errors.currentPassword ? 'item-danger' : 'item-medium']">
      <ion-input
        v-model="currentPassword"
        :value="currentPassword"
        name="currentPassword"
        :placeholder="$t('accountPage.enter_current_password')"
        :type="!peekCurrentPassword ? 'password' : 'text'"
      >
      </ion-input>
      <div slot="end" class="pt-3" @click="peekCurrentPassword = !peekCurrentPassword">
        <ion-icon
          color="grey"
          class="fs-4"
          slot="icon-only"
          :icon="peekCurrentPassword ? eyeOutline : eyeOffOutline"
        />
      </div>
    </ion-item>
    <ion-text v-if="errors.currentPassword" color="danger">
      <div class="pr-1 fs-1">{{ $t(`${errors.currentPassword}`) }}</div>
    </ion-text>

    <!-- new password -->
    <ion-item :class="['fs-2 custom-item', errors.newPassword ? 'item-danger' : 'item-medium']">
      <ion-input
        v-model="newPassword"
        :value="newPassword"
        name="newPassword"
        :placeholder="$t('accountPage.enter_new_password')"
        :type="!peekNewPassword ? 'password' : 'text'"
      >
      </ion-input>
      <div slot="end" class="pt-3" @click="peekNewPassword = !peekNewPassword">
        <ion-icon
          color="grey"
          class="fs-4"
          slot="icon-only"
          :icon="peekNewPassword ? eyeOutline : eyeOffOutline"
        />
      </div>
    </ion-item>
    <ion-text v-if="errors.newPassword" color="danger">
      <div class="pr-1 fs-1">{{ $t(`${errors.newPassword}`) }}</div>
    </ion-text>

    <!-- confirm password -->
    <ion-item :class="['fs-2 custom-item', errors.confirmPassword ? 'item-danger' : 'item-medium']">
      <ion-input
        v-model="confirmPassword"
        :value="confirmPassword"
        name="confirmPassword"
        :placeholder="$t('accountPage.confirm_new_password')"
        :type="!peekConfirmPassword ? 'password' : 'text'"
      >
      </ion-input>
      <div slot="end" class="pt-3" @click="peekConfirmPassword = !peekConfirmPassword">
        <ion-icon
          color="grey"
          class="fs-4"
          slot="icon-only"
          :icon="peekConfirmPassword ? eyeOutline : eyeOffOutline"
        />
      </div>
    </ion-item>
    <ion-text v-if="errors.confirmPassword" color="danger">
      <div class="pr-1 fs-1">{{ $t(`${errors.confirmPassword}`) }}</div>
    </ion-text>

    <ion-button class="btn-save mt-5" expand="full" @click="updatePassword">{{ $t('save') }}</ion-button>
  </vee-form>
</template>

<script>
import { eyeOutline, eyeOffOutline } from 'ionicons/icons';
import { defineComponent } from 'vue';
import { useForm, useField, Form as VeeForm } from 'vee-validate';
import { cipher } from '@/modules/b2b/services/libs/cipher';

export default defineComponent({
  name: 'updatePassword',
  components: {
    VeeForm
    // User Defined component
  },
  data() {
    return {
      firstChange: {
        currentPassword: false,
        newPassword: false,
        confirmPassword: false
      },
      peekCurrentPassword: false,
      peekNewPassword: false,
      peekConfirmPassword: false,
      encrypt: cipher()
    };
  },
  setup() {
    // Define a validation schema
    const productSchema = {
      currentPassword: (val) => (!val ? 'accountPage.old_password_cannot_be_empty' : true),
      newPassword: (val) =>
        !val
          ? 'accountPage.new_password_cannot_be_empty'
          : val.length < 6
          ? 'accountPage.password_must_be_at_least_6_characters'
          : true,
      confirmPassword: (val, { form: { newPassword } }) =>
        !val
          ? 'accountPage.confirm_new_password_cannot_be_empty'
          : val !== newPassword
          ? 'accountPage.confirm_password_does_not_match'
          : true
    };

    // Create a form context with the validation schema
    const { errors, validate, values: product } = useForm({
      validationSchema: productSchema,
      initialValues: {
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
      }
    });

    const { value: currentPassword } = useField('currentPassword');
    const { value: newPassword } = useField('newPassword');
    const { value: confirmPassword } = useField('confirmPassword');

    return {
      productSchema,
      errors,
      validate,
      product,

      // form data
      currentPassword,
      newPassword,
      confirmPassword,

      // icon
      eyeOutline,
      eyeOffOutline
    };
  },
  emits: ['update-password'],
  methods: {
    getColorValidate(name, errMsg) {
      if (this.firstChange[name]) {
        return errMsg ? 'danger' : 'primary';
      }
      return 'medium';
    },
    onInputChange(event) {
      this.firstChange[event.target.name] = true;
    },
    async updatePassword() {
      // set first change is true
      for (const key in this.firstChange) {
        this.firstChange[key] = true;
      }
      // validate form
      const { valid } = await this.validate();
      if (valid) {
        const passwordInfo = {
          currentPassword: this.encrypt(this.currentPassword),
          newPassword: this.encrypt(this.newPassword)
        };
        this.$emit('update-password', passwordInfo);
      }
    }
  }
});
</script>
